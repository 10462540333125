<template>
  <div style="width: 100%">
    <section id="home-s1">
      <div class="s1_box">
        <p v-if="width_1140()">국내선물, 해외선물 모의투자 HTS 통합 솔루션</p>
        <p v-else>국내선물, 해외선물 모의투자<br>HTS 통합 솔루션</p>
        <img src="../assets/images/logo2.png">
        <button @click="contact">프로그램 문의</button>
      </div>
    </section>
    <section id="home-s2">
      <div v-if="width_1140()" class="s2_box">
        <div class="s2_text">
          <p class="p1">시장을 선도하는<br>파생상품 전문업체<br>미라클솔루션입니다.
          </p>
          <p class="p2" style="margin-top: 12px">
            고객과 함께하는 최고의 파트너.<br>
            고객의 성장이 곧 회사의 성장이라 생각하며,<br>
            더욱더 앞서나가겠습니다.
          </p>
        </div>
        <img src="../assets/images/home/s2_img.png" style="width: 426px">
      </div>
      <div v-else class="s2_box">
        <img src="../assets/images/home/s2_img.png" style="width: 426px">
        <div class="s2_text">
          <p class="p1">시장을 선도하는<br>파생상품 전문업체<br>미라클솔루션입니다.
          </p>
          <p class="p2">
            고객과 함께하는 최고의 파트너.<br>
            고객의 성장이 곧 회사의 성장이라 생각하며,<br>
            더욱더 앞서나가겠습니다.
          </p>
        </div>
      </div>
    </section>
    <section id="home-s3">
      <div class="s3_container">
        <div class="s3_container_top">
          <p class="title">MIRACLE SYSTEM</p>
          <img v-if="width_1140()" src="../assets/images/home/s3_img.png">
          <img v-else src="../assets/images/home/s3_img_m.png">
        </div>
        <div class="s3_container_bottom">
          <p class="title">MIRACLE SERVICE</p>
          <p class="content">미라클만의 자체기술을 통하여 다양한 맞춤서비스를 제공합니다.</p>
          <img v-if="width_1140()" src="../assets/images/home/s3_img2.png">
          <img v-else src="../assets/images/home/s3_img2_m.png">
        </div>
      </div>
    </section>
    <section id="home-s4">
      <div class="title" v-if="width_1140()">DEMO SERVICE</div>
      <div class="p1">미라클솔루션은<br/>
        데모서비스를 지원합니다.</div>
      <div v-if="width_1140()" class="p2">데모 버전은 사용자가 쉽게 사용할 수 있도록 기본 데이터가 입력되어 있습니다.<br/>
        기능은 정식 프로그램과 동일합니다.</div>
      <div v-else class="p2">데모 버전은 사용자가 쉽게 사용할 수 있도록 기본 데이터가 입력되어 있습니다.
        기능은 정식 프로그램과 동일합니다.</div>
      <div class="p3">단, 본 버전은 다수의 사용자가 PC로 체험하는 것이므로, 일부 기능과 특정 솔루션이 사용 제한됩니다</div>
      <button @click="contact">
        데모서비스 신청하기
      </button>
    </section>
    <div id="support-page">
      <div class="support_container">
        <div v-if="width_1140()" class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해 노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <div v-else class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해<br/>노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <img src="../assets/images/home/support_img.png">
      </div>
    </div>
  </div>
</template>

<script>
//  import {swiper, swiperSlide} from 'vue-awesome-swiper'
//  import 'swiper/swiper-bundle.css'

  const S4_DATA = [{
    id: 1, theme: 'Blockchain Core Implementation',
    title: '블록체인 기반 기술 구현',
    list: [{
      id: 1, content: '블록체인 노드 구축'
    }, {
      id: 2, content: '신규 가상화폐 개발'
    }, {
      id: 3, content: '디지털 전자지갑 개발'
    }, {
      id: 4, content: '블록체인 탐색기 개발'
    }, {
      id: 5, content: '블록체인 RPC 통신 모듈 개발'
    },]
  }, {
    id: 2, theme: 'Web Aplication',
    title: '전문적이고 특화된 웹솔루션 개발',
    list: [{
      id: 1, content: '기업 홈페이지 제작'
    }, {
      id: 2, content: '기업 업무용 전문 웹 어플리케이션 제작'
    }, {
      id: 3, content: '웹 크롤링 및 HTML 파싱'
    }, {
      id: 4, content: '다양한 OS, 플랫폼에서의 웹서버 구축'
    }, {
      id: 5, content: '강력한 성능의 WAS'
    },]
  }, {
    id: 3, theme: 'Mobile Platform',
    title: '모바일 솔루션',
    list: [{
      id: 1, content: '음식 배달, 중고차 거래 등 전문 마켓 플랫폼'
    }, {
      id: 2, content: 'Social Commerce'
    }, {
      id: 3, content: 'SNS - Social Network Service'
    }, {
      id: 4, content: '각종 Community 플랫폼'
    }, {
      id: 5, content: 'Live Chat 플랫폼'
    },]
  }]

  export default {
    name: "Home",
    components: {
//      swiper,
//      swiperSlide
    },
    data() {
      return {
        S4_DATA,
        windowWidth: window.innerWidth
      }
    },
    methods:{
      width_1140(){
        if (this.windowWidth > 1140){
         return true
        }
      },
      contact(){
        window.open('https://t.me/jackjackcc', '_blank')
      }
    },
    created() {
      console.log('resize', this.windowWidth);
    },
  }
</script>
