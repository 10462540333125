import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import './assets/styles/main.scss'
import { Drawer } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.use(Drawer);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
