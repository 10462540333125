<template>
  <div>

    <div id="Footer">
      <div class="logo-layout">
        <img src="../assets/images/logo2.png"/>
        <p>© 2024 Miracle Technology Corp. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
  }
</script>
