<template>
  <div id="futures_solution">
    <section id="solution-s1">
      <div class="section-inner">
        <div class="s1_conatiner">
          <p class="p1">DEMO SERVICE</p>
          <p class="p2">해외선물</p>
          <p class="p3">미라클의 독보적인 HTS/MTS기술을 경험해보세요.</p>
        </div>
      </div>
    </section>
    <section id="solution-s2">
      <div class="section-inner">
        <div class="text_box">
          <p v-if="width_1140()" class="p1">선물 매매도 이제 Miracle 하게! 해외선물의 시작.</p>
          <p v-else class="p1">선물 매매도 이제<br/>Miracle 하게!<br/>해외선물의 시작.</p>
          <p v-if="width_1140()" class="p2">안정적인 시세와 사용자맞춤 UI/UX 모의거래 완벽 지원!</p>
          <p v-else class="p2">안정적인 시세와<br/>사용자맞춤UI/UX 모의거래<br/>완벽 지원!</p>
        </div>
        <img src="../assets/images/solution/f_s2_img.png">
      </div>
    </section>
    <div id="mobile_content">
      <swiper class="swiper content-box" :options="swiperOption">
        <swiper-slide v-for="m in DATA" :key="m.id">
          <div class="box">
<!--            <p class="bb-title">{{m.title}}</p>-->
            <div class="img-layout">
              <img :src="m.img"/>
            </div>
<!--            <div class="box-bottom">-->
<!--              <ul>-->
<!--                <li v-for="v in m.list" :key="v.id">-->
<!--                  <p><i class="el-icon-caret-right"/>{{v.content}}</p>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div id="pc_content">
      <section id="solution-s3">
        <div class="section-inner">
          <img src="../assets/images/solution/f_s3_img.png">
          <div class="text_box">
            <p class="title">빠르고 편리한호가창</p>
            <p class="content"><i class="el-icon-caret-right"></i>신속한 현재가, 호가 정보</p>
            <p class="content"><i class="el-icon-caret-right"></i>간편한 계좌실시간현황 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>종목 세부정보 제공 [만기일,틱가치,수수료,증거금등]</p>
            <p class="content"><i class="el-icon-caret-right"></i>자동익절,자동 손절 MIT 기능</p>
            <p class="content"><i class="el-icon-caret-right"></i>HTS프로그램제공,모바일형 MTS제공</p>
          </div>
        </div>
      </section>
      <section id="solution-s4">
        <div class="section-inner">
          <div class="text_box">
            <p class="title">중요 경제 지표 발표 지원</p>
            <p class="content"><i class="el-icon-caret-right"></i>제공 종목과 관련해 엄선된 지표 이벤트 정보 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>시장에 미치는 영향의 중요정보 지원</p>
            <p class="content"><i class="el-icon-caret-right"></i>예정 지표,예상 수치,기존 수치,결과값 제공</p>
          </div>
          <img src="../assets/images/solution/f_s4_img.png">
        </div>
      </section>
      <section id="solution-s4_2">
        <div class="section-inner">
          <img src="../assets/images/solution/f_s4_2_img.png">
          <div class="text_box">
            <p class="title">빠르고 정확한<br/>
              고성능 네이티브 차트 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>신속한 현재가, 호가 정보</p>
            <p class="content"><i class="el-icon-caret-right"></i>간편한 계좌실시간현황 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>종목 세부정보 제공 [만기일,틱가치,수수료,증거금등]</p>
            <p class="content"><i class="el-icon-caret-right"></i>자동익절,자동 손절 MIT 기능</p>
            <p class="content"><i class="el-icon-caret-right"></i>HTS프로그램제공,모바일형 MTS제공</p>
          </div>
        </div>
      </section>
      <section id="solution-s5">
        <div class="s5_container">
          <div class="top">
            <p class="p1">해외선물 데모서비스</p>
            <p class="p2">데모 버전은 사용자가 쉽게 사용할 수 있도록 기본 데이터가 입력되어 있습니다.
              기능은 정식 프로그램과 동일합니다.</p>
          </div>
          <div class="bottom">
            <p>단, 본 버전은 다수의 사용자가 PC로 체험하는 것이므로, 일부 기능과 특정 솔루션이 사용 제한됩니다.</p>
<!--            <button style="margin-right: 12px">HTS 다운로드</button>-->
            <button @click="contact">프로그램 문의</button>
          </div>
        </div>
      </section>
    </div>
    <div id="support-page">
      <div class="support_container">
        <div v-if="width_1140()" class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해 노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <div v-else class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해<br/>노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <img src="../assets/images/home/support_img.png">
      </div>
    </div>
  </div>
</template>

<script>
  import {swiper, swiperSlide} from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'

  const DATA = [
    {
      id: 1,
      img: require(`../assets/images/solution/4.png`),
      title: '빠르고 편리한 호가창',
      list: [{
        id: 1, content: '신속한 현재가,호가정보'
      }, {
        id: 2, content: '간편한 계좌 실시간현황 제공'
      }, {
        id: 3, content: '종목 세부정보 제공 [만기일,틱가치,수수료,증거금등]'
      }, {
        id: 4, content: '자동익절, 자동손절 MIT 기능'
      }, {
        id: 5, content: 'HTS프로그램제공,모바일형 MTS제공'
      },]
    },
    {
      id: 2,
      img: require(`../assets/images/solution/5.png`),
      title: '중요경제 지표 발표지원',
      list: [{
        id: 1, content: '제공 종목과 관련해 엄선된 지표 이벤트 정보 제공'
      }, {
        id: 2, content: '시장에 미치는 영향의 중요정보 지원'
      }, {
        id: 3, content: '예정 지표, 예상수치, 기존수치,결과값 제공'
      }, ]
    },
    {
      id: 3,
      img: require(`../assets/images/solution/6.png`),
      title: '빠르고 정확한 고성능 네이티브 차트 제공',
      list: [{
        id: 1, content: '실시간 시세 수신에 따라 빠르고 정확한  차트제공'
      }, {
        id: 2, content: '분 , 시간 , 일, 주, 월 다양한 봉 지원'
      },
        {
          id: 3, content: '해당 봉에 마우스 오버시 시/고/저/정 표시'
        },
        {
          id: 4, content: '단기, 중기,장기 이동평균선 지원'
        },
      ]
    },
  ]

  export default {
    name: "Solution",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        swiperOption: {
          autoplay: {delay: 3500,},
//          spaceBetween: 25,
          loop: true,
          slidesPerView: 1,
          centeredSlides: true,
          autoHeight: true,
        },
        DATA,
      }
    },
    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      },
      clickDemo(type) {
        const result = this.isMobile()
        if (!result) {
          if (type === 'web') {
            window.open('https://coin.miracle24.net', '데모 WEB')
          } else {
            window.open('https://m-coin.miracle24.net', '데모 MOBILE', 'resizable=no,width=374,height=667')
          }
        } else {
          window.open('https://coin.miracle24.net')
        }
      },
      width_1140() {
        if (this.windowWidth > 1140) {
          return true
        }
      },
      contact(){
        window.open('https://t.me/jackjackcc', '_blank')
      }
    }
  };
</script>
