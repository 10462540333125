<template>
  <div id="coin_solution">
    <section id="solution-s1">
      <div class="section-inner">
        <div class="s1_conatiner">
          <p class="p1">DEMO SERVICE</p>
          <p class="p2">코인선물</p>
          <p class="p3">미라클만의 더빠르고 쾌적한 거래환경을 경험해보세요.</p>
        </div>
      </div>
    </section>
    <section id="solution-s2">
      <div class="section-inner">
        <div class="text_box">
          <p v-if="width_1140()" class="p1">선물 매매도 이제 Miracle 하게! 코인선물의 시작.</p>
          <p v-else class="p1">선물 매매도 이제<br/>Miracle 하게!<br/>코인선물의 시작.</p>
          <p v-if="width_1140()" class="p2">안정적인 시세와 사용자맞춤 UI/UX 모의거래 완벽 지원!</p>
          <p v-else class="p2">안정적인 시세와<br/>사용자맞춤UI/UX 모의거래<br/>완벽 지원!</p>
        </div>
        <img src="../assets/images/solution/c_s2_img.png">
      </div>
    </section>
    <div id="pc_content">
      <section id="solution-s3">
        <div class="section-inner">
          <img src="../assets/images/solution/c_s3_img.png">
          <div class="text_box">
            <p class="title">맞춤형 최적화 거래시스템 지원</p>
            <p class="content"><i class="el-icon-caret-right"></i>BTC , ETH, XRP 등 다양한코인 거래 지원</p>
            <p class="content"><i class="el-icon-caret-right"></i>현물20X , 선물거래 125X 레버리지 지원 가능</p>
            <p class="content"><i class="el-icon-caret-right"></i>클라이언트 맞춤지원 서비스 다국어 언어 셋팅 가능</p>
            <p class="content"><i class="el-icon-caret-right"></i>코인입금, P2P 지갑 서비스 지원가능</p>
            <p class="content2"><i class="el-icon-caret-right"></i>자동 로스컷 기능, 스탑로스 기능, 등 타 거래소 가능기술 구현.</p>
            <p class="content3">(카피트레이딩 등 클라이언트 맞춤서비스 추가지원)</p>
          </div>
        </div>
      </section>
      <section id="solution-s4">
        <div class="section-inner">
          <div class="text_box">
            <p class="title">회원통합관리자서비스 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>웹/ 어드민 / 디자인 시스템 / 전방적인 이용 메뉴얼 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>테스트 서버를 통한 안정화 테스트(QA)</p>
            <p class="content"><i class="el-icon-caret-right"></i>지속적인 유지보수와 업데이트를 통해 최상의 서비스 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>실시간 서버관리와 1대1 24시간 상담</p>
          </div>
          <img src="../assets/images/solution/c_s4_img.png">
        </div>
      </section>
      <section id="solution-s4_2">
        <div class="section-inner">
          <img src="../assets/images/solution/c_s4_2_img.png">
          <div class="text_box">
            <p class="title">회원통합관리자서비스 제공</p>
            <p class="content"><i class="el-icon-caret-right"></i>관리자모드에서 전문가 및 하위전문가를 지정하여 파트너</p>
            <p class="br_content">시스템으로 관리</p>
            <p class="content"><i class="el-icon-caret-right"></i>회원별 수수료 및 로스컷기준 등 회원별 개인설정기능 모두 통합</p>
            <p class="br_content">관리자에서 설정 가능</p>
          </div>
        </div>
      </section>
      <section id="solution-s5">
        <div class="s5_container">
          <div class="top">
            <p class="p1">코인선물 데모서비스</p>
            <p class="p2">데모 버전은 사용자가 쉽게 사용할 수 있도록 기본 데이터가 입력되어 있습니다.
              기능은 정식 프로그램과 동일합니다.</p>
          </div>
          <div class="bottom">
            <p>단, 본 버전은 다수의 사용자가 PC로 체험하는 것이므로, 일부 기능과 특정 솔루션이 사용 제한됩니다.</p>
            <button style="margin-right: 12px" @click="clickDemo('web')">거래소 웹 데모</button>
            <button @click="clickDemo('mobile')">거래소 모바일 데모</button>
          </div>
        </div>
      </section>
    </div>
    <div id="mobile_content">
      <swiper class="swiper content-box" :options="swiperOption">
        <swiper-slide v-for="m in DATA" :key="m.id">
          <div class="box">
            <!--            <p class="bb-title">{{m.title}}</p>-->
            <div class="img-layout">
              <img :src="m.img"/>
            </div>
            <!--            <div class="box-bottom">-->
            <!--              <ul>-->
            <!--                <li v-for="v in m.list" :key="v.id">-->
            <!--                  <p><i class="el-icon-caret-right"/>{{v.content}}</p>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div id="support-page">
      <div class="support_container">
        <div v-if="width_1140()" class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해 노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <div v-else class="text_box">
          <p class="p1">COUNSELING REQUEST</p>
          <p class="p2">미라클솔루션은 고객님의 눈높이에<br/>
            맞추어 최상의 서비스가 되기 위해<br/>노력합니다.</p>
          <button @click="contact">프로그램 문의</button>
        </div>
        <img src="../assets/images/home/support_img.png">
      </div>
    </div>
  </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {dummyAsync} from "@/misc";

const DATA = [
  {
    id: 1,
    img: require(`../assets/images/solution/1.png`),
    title: '맞춤형 최적화 거래시스템 지원',
    list: [{
      id: 1, content: 'BTC , ETH, XRP 등 다양한코인 거래 지원'
    }, {
      id: 2, content: '현물20X , 선물거래 125X 레버리지 지원 가능'
    }, {
      id: 3, content: '클라이언트 맞춤지원 서비스 다국어 언어 셋팅 가능'
    }, {
      id: 4, content: '코인입금, P2P 지갑 서비스 지원가능'
    }, {
      id: 5, content: '자동 로스컷 기능, 스탑로스 기능, 등 타 거래소 가능기술 구현.(카피트레이딩 등 클라이언트 맞춤서비스 추가지원)'
    },]
  },
  {
    id: 2,
    img: require(`../assets/images/solution/2.png`),
    title: '솔루션 지원시스템',
    list: [{
      id: 1, content: '웹/ 어드민 / 디자인 시스템 / 전방적인 이용 메뉴얼 제공'
    }, {
      id: 2, content: '테스트 서버를 통한 안정화 테스트(QA)'
    }, {
      id: 3, content: '지속적인 유지보수와 업데이트를 통해 최상의 서비스 제공'
    }, {
      id: 4, content: '실시간 서버관리와 1대1 24시간 상담'
    },]
  },
  {
    id: 3,
    img: require(`../assets/images/solution/3.png`),
    title: '회원통합관리자 서비스제공',
    list: [{
      id: 1, content: '관리자모드에서 전문가 및 하위전문가를 지정하여 파트너 시스템으로 관리.'
    }, {
      id: 2, content: '회원별 수수료 및 로스컷기준 등 회원별 개인설정기능 모두 통합 관리자에서 설정 가능'
    },]
  },
]

export default {
  name: "Solution",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      swiperOption: {
        autoplay: {delay: 3500,},
//          spaceBetween: 25,
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        autoHeight: true,
      },
      DATA,
    }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    clickDemo(type) {
//        const result = this.isMobile()
//        if (!result) {
      if (type === 'web') {
        dummyAsync({link: 'https://coin.miracle24.net', features: null}).then((obj) => {
          window.open(obj.link, '_blank', obj.features)
        })
      } else {
        dummyAsync({link: 'https://m-coin.miracle24.net', features: 'resizable=no,width=374,height=667'})
        .then((obj) => {
          window.open(obj.link, '_blank', obj.features)
        })
      }
//        } else {
//          window.open('https://coin.miracle24.net')
//        }
    },
    width_1140() {
      if (this.windowWidth > 1140) {
        return true
      }
    },
    contact() {
      window.open('https://t.me/jackjackcc', '_blank')
    }
  }
};
</script>
